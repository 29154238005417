import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';

import { IonicModule } from '@ionic/angular';
import { TranslateModule } from '@ngx-translate/core';
import { AddCardPage } from './add-card.page';

/*const routes: Routes = [
  {
    path: '',
    component: AddCardPage
  }
];*/

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    IonicModule,
    TranslateModule,
    // RouterModule.forChild(routes)
  ],
  declarations: [AddCardPage],
  entryComponents: [AddCardPage]
})
export class AddCardPageModule {}
