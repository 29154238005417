import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { IonicModule } from '@ionic/angular';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { NannyReviewsPage } from './nanny-reviews.page';
import { IonicRatingModule } from 'ionic4-rating';



@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    IonicModule,
    TranslateModule,
    ReactiveFormsModule,
    IonicRatingModule,
  ],
  declarations: [NannyReviewsPage],
  entryComponents: [NannyReviewsPage]
})
export class NannyReviewsPageModule {}
