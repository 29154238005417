import { Component, OnInit } from '@angular/core';
import { TranslatePipe } from '@ngx-translate/core';
import { NannyReviewsService, CommonService } from '../../services';
import { IUser, IRating } from 'src/app/interfaces';
import { Router, ActivatedRoute } from '@angular/router';
import { FormControl } from '@angular/forms';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ModalController, NavParams } from '@ionic/angular';

@Component({
  selector: 'app-nanny-reviews',
  templateUrl: './nanny-reviews.page.html',
  styleUrls: ['./nanny-reviews.page.scss'],
  providers: [TranslatePipe]

})
export class NannyReviewsPage implements OnInit {
  public ratingForm: FormGroup;
  public nannyId: number ;
  public nanny: IUser = null;
  public submitAttempt: boolean = false;
  public comment: string;
  public title: string;
  public rating: number;
  public ratingNanny: IRating;
  public data = [];
  public theRating: any;

  constructor(
    public formBuilder: FormBuilder,
    public nannyReviewsService: NannyReviewsService,
    public commonService: CommonService,
    public translate: TranslatePipe,
    public router: Router,
    public route: ActivatedRoute,
    public modalController: ModalController,
    public navParams: NavParams

  ) {
    console.log('NannyReviewsPage');
    this.commonService.translate = this.translate;
  }

  ionViewDidEnter() {
    console.log('NannyReviewsPage', 'ionViewDidEnter');
    this.nannyId = this.navParams.data.nanny.id;
    console.log('nanny', this.nannyId);

  }

  ngOnInit() {
    this.nannyId = this.navParams.data.nanny.id;
    console.log('nanny', this.nannyId);
    console.log('NannyReviewsPage', 'ngOnInit');
    this.ratingForm = this.formBuilder.group({
      title: new FormControl('', Validators.compose([Validators.required])),
      comment: new FormControl('', Validators.compose([Validators.required])),
      rating: new FormControl(''),

    });

    }

  ratingNannies() {
    this.commonService.showLoading().then(() => {
      this.nannyReviewsService.post({
        nanny: this.nannyId,
        title: this.title,
        comment: this.comment,
        rating: this.rating

      }).$observable.subscribe(data => {
        this.theRating = data ;
        console.log('ratingForm - data response: ', this.theRating);
        this.commonService.hideLoading();
        this.modalController.dismiss(null);
        // this.router.navigateByUrl('/family-reservations');
      }, (error) => this.commonService.handleError(error));
    });
  }

  onModelChange(event) {
    console.log('rating', event);
  }


  dismissModal() {
    this.modalController.dismiss(null);
  }

}
